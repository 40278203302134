<template>
   <div id="content1" class="pos-r dis-flex4">
    <div class="content1_box">
      <div class="fz40 plan-title center">更垂直的快消品行业解决方案</div>
      <div class="dis-flex"><div class="paln_btn fz26 color-fff">快消品</div></div>
      <div class="dis-flex plan_member_title"><div class="color333 fz32">快消品会员商城解决方案</div></div>
      <div class="dis-flex plan_member_info"><div class="fz16 color333">会员商城解决方案致力于为快消品行业提供多渠道电商解决方案，通过自建商城，以及营销系统、分销系统、会员系统帮助企业搭建新一代商城销售体系，快速实现去中心化流量聚合，客户粉丝沉淀，助力企业快速步入移动电商时代。</div></div>
      <div>
        <el-row :gutter="10">
          <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
            <div class="">
              <div class="dis-flex4"><img class="paly_img" :src="require('@/assets/img/icon_yinliuhuoke.png')" alt=""></div>
              <div class="center fz20 plan_top">引流获客</div>
            </div>
          </el-col>
          <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
             <div class="">
               <div class="dis-flex4"> <img class="paly_img" :src="require('@/assets/img/icon_zaixianshangc.png')" alt=""></div>
              <div class="center fz20 plan_top">在线商城</div>
            </div>
          </el-col>
          <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
            <div class="">
              <div class="dis-flex4"><img class="paly_img" :src="require('@/assets/img/icon_daodianziti.png')" alt=""></div>
              <div class="center fz20 plan_top">到店自提</div>
            </div>
          </el-col>
          <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
            <div class="">
              <div class="dis-flex4"><img class="paly_img" :src="require('@/assets/img/icon_shangchaofuli.png')" alt=""></div>
              <div class="center fz20 plan_top">商超福利</div>
            </div>
          </el-col>
          <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
            <div class="">
              <div class="dis-flex4"><img class="paly_img" :src="require('@/assets/img/icon_huiyuanyuny.png')" alt=""></div>
              <div class="center fz20 plan_top">会员运营</div>
            </div>
          </el-col>
          <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
            <div class="">
              <div class="dis-flex4"><img class="paly_img" src="../../assets/img/icon_tuiguangfeny.png" alt=""></div>
              <div class="center fz20 plan_top">推广分拥</div>
            </div>
          </el-col>
          <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
            <div class="">
              <div class="dis-flex4"><img class="paly_img" src="../../assets/img/icon_znyingxiao.png" alt=""></div>
              <div class="center fz20 plan_top">智能营销</div>
            </div>
          </el-col>
          <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
            <div class="">
              <div class="dis-flex4"><img class="paly_img" src="../../assets/img/icon_shujufenxi.png" alt=""></div>
              <div class="center fz20 plan_top">数据分析</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:"Plan"
}
</script>

<style lang="scss" scoped>
#content1{
  width:100%;
  background:#F6FAFF;
  .content1_box{
    width: 1200px;
    padding:60px 0;
    .plan-title{
      padding:0 0 40px 0;
    }
    .plan_member_title{
      padding:32px 0 20px 0;
    }
    .plan_member_info{
      padding-bottom:40px;
    }
    .paln_btn{
      background:#0F6FFF;
      padding:14px 31px;
      border-radius: 8px;
    }
    .plan_top{
      padding-top:10px;
    }
  }
}
</style>