<template>
  <div class="content dis-flex4">
    <div class="content_box">
      <div class="dis-flex2 content_box_index content_box_line">
        <div class="dis-flex2">
          <img class="foot_img" src="../../assets/img/icon_gengxin.png" alt="">
          <div>
            <div class="fz18 color-fff margin_font_bottom">每周版本迭代更新</div>
            <div class="fz14 color-grey">功能体验持续升级</div>
          </div>
        </div>
        <div class="dis-flex2">
          <img class="foot_img" src="../../assets/img/icon_peixun.png" alt="">
          <div>
            <div class="fz18 color-fff margin_font_bottom">免费系统培训支持</div>
            <div class="fz14 color-grey">让店铺运营更轻松</div>
          </div>
        </div>
        <div class="dis-flex2">
          <img class="foot_img" src="../../assets/img/icon_fuwu.png" alt="">
          <div>
            <div class="fz18 color-fff margin_font_bottom">7*24小时服务支持</div>
            <div class="fz14 color-grey">全时段售后无忧响应</div>
          </div>
        </div>
      </div>
      <div class="dis-flex2 content_box_index">
        <div class="dis-flex2">
          <div>
            <div class="fz18 color-grey solve_plan pos-r">解决方案
              <div class="Highlight"></div>
            </div>
            <div class="fz14 color-grey planSlove" @click="changeStatus">快消品会员商城解决方案</div>
          </div>
        </div>
        <div class="dis-flex2">
          <div>
            <div class="fz18 color-grey solve_plan pos-r">联系我们<div class="Highlight"></div></div>
            <div class="fz14 color-grey dis-flex">
              <div class="icon_phone_box1"><img class="icon_phone" src="../../assets/img/icon_dizhi.png" alt=""></div>
              <div class="fz14">浙江省杭州市上城区凤起东路111号中豪五福天地11F</div>
            </div>
            <div class="fz14 color-grey dis-flex">
              <div class="icon_phone_box"><img class="icon_phone" src="../../assets/img/icon_dianhua.png" alt=""></div>
              <div class="fz14"><span class="fz20 color-fff">0571-28874408</span>(服务时间:09:00~18:00)</div>
            </div>
          </div>
        </div>
        <div class="dis-flex2">
          <div class="">
            <img class="qc_img" src="../../assets/img/er_wei_ma.jpg" alt="">
            <div class="center color-grey fz12">现在咨询立享优惠</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
export default {
  name:"Footers",
  emits:['changeStyle'],
  setup(props,cxt){
    const router = useRouter()
    const changeStatus=()=>{
      cxt.emit('changeStyle')
      router.push({ path: '/solveWay'})
    }
    return {changeStatus}
  }
}
</script>

<style lang="scss" scoped>
  .content{
    background-color:#161E30;
    .content_box{
      width:1200px;
      .content_box_index{
        padding:40px 0;
        .solve_plan{
          padding:16px 0;
          border-bottom:1px solid rgba(204,204,204,0.15);
          margin-bottom:16px;
          .Highlight{
            position: absolute;
            bottom:0;
            left:0;
            width:30px;
            height:3px;
            background:#989898
          }
        }
        .icon_phone_box1{
          margin-bottom:16px;
        }
        .icon_phone{
          width:14px;
          height:14px;
          margin-right: 12px;
        }
        .margin_font_bottom{
          margin-bottom: 4px;
        }
        .planSlove{
          cursor: pointer;
        }
      }
      .content_box_line{
        border-bottom: 1px solid rgba(204,204,204,0.15);
      }
      .qc_img{
        width:128px;
        height:128px;
        margin-bottom: 6px;
      }
      .foot_img{
        width:42px;
        height:42px;
        margin-right:30px;
      }
    }
  }
</style>