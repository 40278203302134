<template>
  <div class="content dis-flex4">
    <div class="content_box">
      <div class="fz40 plan-title center">钜汇会员商城托管代运营服务</div>
      <div class="dis-flex serve_box">
        <div class="flex serve_flu">
          <div class="dis-flex4 serve_img"><img src="../../assets/img/icon_cehua.png" alt=""></div>
          <div class="center color333 fz24">策划</div>
          <div class="serve_copywriting center fz16 color333">营销策略策划<br>
              品牌传播策划<br>
              会员运营策划<br>
              扫码互动策划</div>
        </div>
        <div class="flex serve_flu">
          <div class="dis-flex4 serve_img"><img src="../../assets/img/icon_sheji.png" alt=""></div>
          <div class="center color333 fz24">设计</div>
          <div class="serve_copywriting center fz16 color333">品牌视觉设计<br>
            产品原型设计<br>
            界面UI设计<br>
            体验交互设计</div>
        </div>
        <div class="flex serve_flu">
          <div class="dis-flex4 serve_img"><img src="../../assets/img/icon_tuiguang.png" alt=""></div>
          <div class="center color333 fz24">推广</div>
          <div class="serve_copywriting center fz16 color333">公域会场亿级曝光<br>
            垂直频道定向推广<br>
            私域阵地精准触达<br>
            资源整合联动宣传</div>
        </div>
        <div class="flex serve_flu">
          <div class="dis-flex4 serve_img"><img src="../../assets/img/icon_kefu.png" alt=""></div>
          <div class="center color333 fz24">客服</div>
          <div class="serve_copywriting center fz16 color333">全托管制云客服<br>
            24h内极速响应<br>
            9h*7d全程在线<br>
            n对一专属服务</div>
        </div>
        <div class="flex serve_flu">
          <div class="dis-flex4 serve_img"><img src="../../assets/img/icon_shuju.png" alt=""></div>
          <div class="center color333 fz24">数据</div>
          <div class="serve_copywriting center fz16 color333">访问数据埋点及收集<br>
            运营数据挖掘及分析<br>
            用户画像打标及绘制<br>
            活动数据跟踪及监测</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
  .content_box{
    width:1200px;
    padding-bottom:80px;
    .plan-title{
      padding:60px 0;
    }
    .serve_box{
      flex-wrap: wrap;
    }
    .serve_flu{
      border:1px solid #C1D9FF;
      border-radius: 16px;
      width:240px;
      .serve_img{
        padding:60px 80px 32px 80px;
      }
      .serve_copywriting{
        padding:10px 20px;
        padding-bottom:58px;
      }
    }
  }
</style>