<template>
  <div class="content">
    <div class="fz12 color-fff center bottom-title">杭州钜汇科技有限公司 版权所有 Copyright  2020-2021 
      <a href="https://beian.miit.gov.cn">浙ICP备19025074号</a> 
      | <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010202002019"><img style="margin-right:3px;display: inline-block;vertical-align: baseline;" src="https://fmcg.oss-cn-hangzhou.aliyuncs.com/test/goods/iQUzZi1qfodPBnIVNRwlrMJafJzXfiQk.png">浙公网安备 33010202002019号</a></div>
  </div>
</template>

<script>
export default {
  name:'Bottom'
}
</script>

<style lang="scss" scoped>
  .content{
    background:#1C2742;
    .bottom-title{
      padding:22px 0;
      vertical-align: baseline;
      color:#6b798e;
    }
    a{
      color:#6b798e;
      text-decoration: none;
    }
  }
</style>