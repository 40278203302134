<template>
<div ref="wrapper" class="containers" :style="{background:tapInfos.bgColor}">
  <!-- container -->
  <div>
    <div :class="{container:true}">
      <el-row>
        <el-col :span="24">
          <div class="navigation dis-flex2">
          <!-- <div class="navigation dis-flex2 hidden-md-and-down"> -->
            <div class="dis-flex3">
              <img @click="urlHome" :src="tapInfos.url" alt="">
              <div class="dis-flex3 flex1 nav_box">
                <div class="navigation_index pos-r"><router-link :class="{topStyle:true,topStyle1:!tapInfos.status}" to="/">首页</router-link></div>
                <!-- @mouseleave="leaveMouse" -->
                <div class="navigation_index pos-r" @mouseenter="enterMouse" @mouseleave="leaveMouse">
                  <a :class="{planystyle:tapInfos.planColor&&tapInfos.status&&tapInfos.planColor,planystyle1:tapInfos.planColor&&!tapInfos.status&&tapInfos.planColor,infin:!tapInfos.status,curper:true}">解决方案&nbsp;<el-icon>
                          <arrow-down />
                        </el-icon>
                  </a>
                </div>
                <div class="navigation_index pos-r" ><router-link :class="{topStyle:true,topStyle1:!tapInfos.status}" to="/ClassicCase">经典案例</router-link></div>
                <!-- <div class="navigation_index pos-r"><router-link to="/bandGoods">品牌商品</router-link></div> -->
                <div class="navigation_index pos-r"><router-link :class="{topStyle:true,topStyle1:!tapInfos.status}" to="/about">联系我们</router-link></div>
              </div>
            </div>
            <div class="dis-flex3">
              <div class="login-btn color-fff fz14" :style="{color:tapInfos.color1,border:`1px solid ${tapInfos.color1}`}" @click="loginBtn">{{onLogin?'进入后台':'登录'}}</div>
              <!-- <div class="user-btn color-blue fz14" :style="{color:tapInfos.color2,background:tapInfos.color1}" @click="tryBtn">免费试用</div> -->
            </div>
          </div>
          <!-- <div class="navigation_app dis-flex3 dis-flex2 hidden-lg-and-up">
            <img @click="urlHome" :src="tapInfos.url" alt="">
            <div>
               <el-link v-if="tapInfos.status" :underline="false"><el-icon :size="20" color="#FFF" class="no-inherit">
              <Fold />
            </el-icon></el-link>
              <el-link v-else :underline="false"><el-icon :size="20" color="#333" class="no-inherit">
                <Fold />
            </el-icon></el-link>
            </div>
          </div> -->
        </el-col>
      </el-row>
    </div>
  </div>
  <div v-if="showXiaLa" :style="{background:'#fff',height:0,}" class="dis-flex3" :class="{navigation:true,navigation1:true,storeInDown:onShowss,storeOutUp:!onShowss,}" @mouseenter="enterMouse" @mouseleave="leaveMouse">
      <div v-if="onShowss" @click="changeStatus" class="container">
        <router-link class="toStyles" to="/solveWay">
        <img class="icon_hui" :src="require('@/assets/img/icon_huiyuansc.png')" alt="">
       快消品会员商城解决方案</router-link>
      </div>
    </div>
</div>
</template>
<script>
import { useRouter } from 'vue-router'
import { ref,onMounted} from 'vue'
import { useStore } from 'vuex'
import CryptoJS from 'crypto-js'
import { ArrowDown,Fold} from '@element-plus/icons'
export default {
  name: 'Navigation',
  components:{
    ArrowDown,
    Fold
  },
  props:{ 
    tapInfos:Object,

  },
  emits:['enterMouse','leaveMouse','changeStyle'],
  setup(props,cxt){
    const router = useRouter()
    const store = useStore()
    let onShows=false
    let onLogins=false
    let onShowss=ref(onShows)
    let onLogin=ref(onLogins)
    let showXiaLa=ref(false)
    // const tapInfos=reactive({bgColor:'',url:require('@/assets/logo.png'),color1:'#fff',color2:'#0F6FFF',status:true,topH:true,planColor:false})
    // const styleStatus=props.tapInfos.planColor
    let enterMouse=()=>{
      showXiaLa.value=true
      onShowss.value= true
    }
    let leaveMouse=()=>{
      onShowss.value= false
    }
    const urlHome=()=>{
      router.push({ path: '/'})
    }
    const changeStatus=()=>{
      cxt.emit('changeStyle')
      // props.tapInfos.planColor=true
    }
    var aseKey = "12345678" 
    const loginBtn=()=>{
      if(onLogin.value){
        //http://www.mywhh.com/mobile/themesmobile/we10cn_mobile/kxp_admin/index.html
        const dataValue = CryptoJS.AES.encrypt(localStorage.getItem('dataValue'), CryptoJS.enc.Utf8.parse(aseKey), {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
        }).toString();
        if(store.state.typeData==1){
          // window.location.href=`http://localhost/kxp/index.html?dataValue=${dataValue}`
          window.location.href=`https://supplier.jhscrm.com?dataValue=${dataValue}`
        }else if(store.state.typeData==2){
          window.location.href=`https://store.jhscrm.com?dataValue=${dataValue}`
        }
      }else{
        router.push({ path: '/Login',query:{type:1}})
      }
    }
    const tryBtn=()=>{
      router.push({ path: '/Login',query:{type:2}})
    }
    onMounted(()=>{
      if(localStorage.getItem('api_token')){
        if(!store.state.api_token){
          store.commit('AddToken',{api_token:localStorage.getItem('api_token')})
        }
         if(!store.state.typeData){
          store.commit('AddType',{typeData:localStorage.getItem('typeData')})
        }
        onLogin.value=true
      }
    })
    return {loginBtn,onShowss,enterMouse,leaveMouse,changeStatus,onLogin,tryBtn,urlHome,showXiaLa}
  },
}
</script>

<style lang="scss" scoped>
  .containers{
    position:fixed;
    top:0;
    right:0;
    left:0;
    z-index: 5;
    transition:1s;
    // background:#fff
    .navigation1{
     
      .icon_hui{
        width:40px;
        height:40px;
        margin-right:10px;
      }
      .toStyles{
        color:#666
      }

    }
  }
  .container{
    width: 1200px;
    padding:0;
    .navigation{
      // display: flex;
      width:100%;
      min-width:1200px;
      height:64px;
      img{
        width:143px;
        height:32px;
        margin-right:104px;
        cursor: pointer;
      }
      .nav_box{
        width:660px;
      }
      .navigation_index{
        margin-right:40px;
        position: relative;
        padding:20px;
        // top:4px;
        .plan_list{
          width:100%;
          position:absolute;
          bottom:-130px;
          left:0;
          right:0;
          height:98px;
          z-index: 10;
          .plan_list_index{
            width:1200px;
            background:#fff;
          }
        }
      }
      .act-btn{
        float: right;
      }
      .login-btn{
        padding:5px 18px;
        border-radius: 4px;
        margin-right:10px;
        cursor: pointer;
      }
      .user-btn{
        padding:5px 18px;
        background:#fff;
        color:#0F6FFF;
        border-radius: 4px;
        cursor: pointer;
      }
    }
    .navigation_app{
      width:100%;
      height:64px;
      img{
        width:143px;
        height:32px;
        cursor: pointer;
      }
    }
  }
  .xia_la{
    height:0;
  }
  a {
    text-decoration:none;
    // font-weight: bold;
    color: #fff;
    font-size: 16px;
  }
  .topStyle{
    &.router-link-exact-active {
      color: #fff;
    }
    &.router-link-exact-active:before {
    content:'';
    display: block;
    position: absolute;
    bottom:10px;
    left:50%;
    transform: translate(-50%,0);
    width:46px;
    height:2px;
    background: #fff;
    }
  }
  .topStyle1{
    color:#666;
    &.router-link-exact-active {
      color: #0F6FFF;
    }
    &.router-link-exact-active:before {
    content:'';
    display: block;
    position: absolute;
    bottom:10px;
    left:50%;
    transform: translate(-50%,0);
    width:46px;
    height:2px;
    background: #0F6FFF;
    }
  }
  .infin{
    color:#666
  }
.planystyle{
  color:#fff;
}
.planystyle:before {
  content:'';
  display: block;
  position: absolute;
  bottom:10px;
  left:50%;
  transform: translate(-50%,0);
  width:46px;
  height:2px;
  background: #fff;
  transition: width 1s;
  }
  .planystyle1{
    color: #0F6FFF;
  }
.planystyle1:before {
  content:'';
  display: block;
  position: absolute;
  bottom:10px;
  left:50%;
  transform: translate(-50%,0);
  width:46px;
  height:2px;
  background: #0F6FFF;
  transition: width 1s;
  }
  .curper{
    cursor: pointer;
  }
</style>