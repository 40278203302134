<template>
  <div v-if="$route.meta.keepAlive"><Navigation :tapInfos="tapInfos" @changeStyle="changeStyle"/></div>
  <router-view />
  <!-- <Search v-if="$route.meta.keepAlive"></Search> -->
  <Footers v-if="$route.meta.keepAlive" @changeStyle="changeStyle"></Footers>
  <Bottoms v-if="$route.meta.keepAlive"></Bottoms>
  <div v-show="state.show_Bg" class="bg_tankuang_box">
    <img class="bg_tankuang" :src="require('@/assets/img/bg_tankuang.png')" alt="">
    <div class="bg_info">
      <div class="color-fff bg_phone center fz20 fw600">0571-28874408</div>
      <div class="color-fff center fz12">(服务时间:09:00~18:00)</div>
      <!-- <div class="dis-flex4">
        <div class="center bg_btn color-blue">在线客服</div>
      </div> -->
      <div class="dis-flex4">
        <div class="center bg_btn color-blue" @click="state.img_show=true">微信咨询 </div>
      </div>
      <!-- <div class="dis-flex4">
        <div class="center bg_btn color-blue" @click="urlUse">免费试用</div>
      </div> -->
    </div>
    <div v-show="state.onTopScr" class="top_icon_box">
      <div class="dis-flex4">
        <div class="line_top"></div>
      </div>
      <div class="color-fff dis-flex4">
        <div class="top_icon center" @click="topScr">
          <el-icon :size="20">
            <arrow-up-bold />
          </el-icon>
        </div>
      </div>
    </div>
  </div>
  <el-dialog
    v-model="state.img_show"
    title="微信咨询"
    width="30%"
    :before-close="handleClose"
  >
    <el-image
    class="img_icon"
    :src="require('@/assets/img/er_wei_ma.jpg')"
    :hide-on-click-modal="true"
    :preview-src-list="[require('@/assets/img/er_wei_ma.jpg')]"
    :initial-index="4"
    fit="cover"
  />
  </el-dialog>
</template>
<script>
import { useRouter } from 'vue-router'
import { reactive,onMounted} from 'vue'
import { useStore } from 'vuex'
import Navigation from '@/components/Navigation'
import { ArrowUpBold } from '@element-plus/icons'
// import Search from '@/components/Search'
import Footers from '@/components/Footers'
import Bottoms from '@/components/Bottom'
export default {
  name: 'App',
  components:{
    Navigation,
    Footers,
    Bottoms,
    ArrowUpBold
  },
  setup(){
    const router = useRouter()
    const store = useStore()
    let state=reactive({onTopScr:false,show_Bg: true,img_show:false})
    const tapInfos=reactive({bgColor:'',url:require('@/assets/logo.png'),color1:'#fff',color2:'#0F6FFF',status:true,topH:true,planColor:false})
    const changeStyle=()=>{
      tapInfos.planColor=true
    }
    const handleScroll=()=>{
      let scrollTop = window.pageYOffset ||document.documentElement.scrollTop||document.body.scrollTop
      if(scrollTop>100){
        tapInfos.bgColor='#fff'
        tapInfos.color1='#0F6FFF'
        tapInfos.color2='#fff'
        tapInfos.status=false
        tapInfos.url=require('@/assets/img/logo1.png')
      }else{
        tapInfos.bgColor=''
        tapInfos.color1='#fff'
        tapInfos.color2='#0F6FFF'
        tapInfos.status=true
        tapInfos.url=require('@/assets/logo.png')
        state.onTopScr=false
      }
      if(scrollTop>500){
        state.onTopScr=true
      }else{
        state.onTopScr=false
      }
    }
    const topScr=()=>{
      window.pageYOffset=0
      document.documentElement.scrollTop=0
      document.body.scrollTop=0
    }
    const urlUse=()=>{
      router.push({ path: '/Login',query:{type:2}})
    }
    const handleClose=()=>{
      state.img_show=false
    }
    onMounted(()=>{
      window.addEventListener('scroll',handleScroll)
        // let width=document.querySelector("body").clientWidth
        // let fontsize=width/1920*10;//fontsize为当前屏幕的基数字体，相对于设计稿计算得到的。
        // document.querySelector("html").style.fontSize=`${fontsize}px`
      // if(width>=1200){
      // //此处1920为⾃⼰屏幕的宽度，40位基准值，⼀般为16px
      //   let fontsize=width/1920*10;//fontsize为当前屏幕的基数字体，相对于设计稿计算得到的。
      //   document.querySelector("html").style.fontSize=`${fontsize}px`
      //   // $("html").css("font-size",`${fontsize}px`)
      // }//当加载页⾯的时候设置⽣效
      // window.onresize = () => {//当页⾯尺⼨改变的时候⽣效
      //   return (() => {
      //     let width=document.querySelector("body").clientWidth
      //     // let width=$("body").width();
      //     if(width>=1200){
      //       let fontsize=width/1920*10;
      //       document.querySelector("html").style.fontSize=`${fontsize}px`
      //       // $("html").css("font-size",`${fontsize}px`)
      //     }
      //   })()
      // }
    })
    return {state,store,topScr,urlUse,tapInfos,changeStyle,handleClose}
  },
  watch:{
    $route(to){
      window.pageYOffset=0
      document.documentElement.scrollTop=0
      document.body.scrollTop=0
      if(!to.path.includes('solveWay')){
        this.tapInfos.planColor=false
      }else{
        this.tapInfos.planColor=true
      }
      if(to.path.includes('login')||to.path.includes('Login')){
        this.state.show_Bg= false
      }else{
        this.state.show_Bg= true
      }
      
    },
  }
}
</script>
<style lang="scss" >
  #nav {
  background:#fff ;
  // text-align: center;
    .bg_banner{
      height:600px;
    }

  }
  .img_box{
    position: fixed;
    width:100%;
    height:100%;
    background-color:rgba(0,0,0,0.2)
  }
  .bg_tankuang_box{
    position: fixed;
    right:20px;
    bottom:50%;
    height:390px;
    transform: translateY(50%);
    z-index: 100;
    transition:1s;
  .bg_info{
    position: absolute;
    top:0;
    right:0;
    left:0;
    bottom:0;
    .bg_phone{
      padding-top:70px;

    }
    .bg_btn{
      width:140px;
      padding:4px 0;
      background:#fff;
      border-radius: 15px;
      margin-top:12px;
      cursor: pointer;
      position: relative;
      .img_icon{
        position: absolute;
        top:0;
        right:0;
        bottom:0;
        left:0;
        opacity: 0;
      }
    }
  }
  .line_top{
    width:2px;
    height:40px;
    background:rgb(101,150,255)
  }
  .top_icon{
    width:50px;
    height:50px;
    border-radius: 50%;
    background:rgb(101,150,255);
    line-height: 45px;
  }
}
.bg_tankuang{
  width:232px;
  height:266px;
}
</style>

