<template>
  <div class="content_bg pos-r">
    <img class="content_img" src="https://fmcg.oss-cn-hangzhou.aliyuncs.com/pc/bg_shuju.png" alt="">
    <el-carousel class="swiper_box" height="100%" :interval="5000" :autoplay="false" arrow="always">
      <el-carousel-item class="swiper_box" v-for="item in swiper_options.info" :key="item">
        <div class="center band_top color-fff fz40">快消品私域会员商城首选钜汇</div>
        <div class="band_info">
          <div class="band_img_box dis-flex4"><img class="band_img" :src="item.brand_logo" alt=""></div>
          <div class="band_title center fz28 color-fff">{{item.brand_name}}</div>
        </div>
        <!-- {title:'快消品私域会员商城首选钜汇',brand_name:'新希望',brand_logo:require('@/assets/img/xxw_logo.png'),user_num:'274W+',community:'27W+',month_num:'200W+',month_order:'5W+',time_end:'数据截止2021年9月'}, -->
        <div class="dis-flex6 color-fff">
          <div class="center fz64">
            <div>{{item.user_num}}</div>
            <div class="fz20">累计用户</div>
          </div>
          <div class="center fz64">
            <div>{{item.community}}</div>
            <div class="fz20">会员沉淀</div>
          </div>
          <div class="center fz64">
            <div>{{item.month_num}}</div>
            <div class="fz20">月均GMV</div>
          </div>
          <div class="center fz64">
            <div>{{item.month_order}}</div>
            <div class="fz20">月均订单</div>
          </div>
        </div>
        <div class="fz16 color-fff center time_title">{{item.time_end}}</div>
      </el-carousel-item>
    </el-carousel>
    <!-- <swiper
      class="swiper-container swiper_img"
      :navigation="swiper_options.navigation"
      :speed="swiper_options.speed"
      :loop="swiper_options.loop"
      :slidesPerView="swiper_options.slidesPerView"
    >
      <swiper-slide class="swiper_img_index">
        <div class="center band_top color-fff fz40">快消品私域会员商城首选钜汇</div>
        <div class="band_info">
          <div class="band_img_box dis-flex4"><img class="band_img" :src="require('@/assets/img/xxw_logo.png')" alt=""></div>
          <div class="band_title center fz28 color-fff">新希望</div>
        </div>
        <div class="dis-flex6 color-fff">
          <div class="center fz64">
            <div>215W+</div>
            <div class="fz20">累计用户</div>
          </div>
          <div class="center fz64">
            <div>27W+</div>
            <div class="fz20">会员沉淀</div>
          </div>
          <div class="center fz64">
            <div>200W+</div>
            <div class="fz20">月均GMV</div>
          </div>
          <div class="center fz64">
            <div>5W+</div>
            <div class="fz20">月均订单</div>
          </div>
        </div>
        <div class="fz16 color-fff center time_title">数据截止2021年9月</div>
      </swiper-slide>
      <swiper-slide class="swiper_img_index">
        <div class="center band_top color-fff fz40">快消品私域会员商城首选钜汇</div>
        <div class="band_info">
          <div class="band_img_box dis-flex4"><img class="band_img" :src="require('@/assets/img/qiaqia_logo.png')" alt=""></div>
          <div class="band_title center fz28 color-fff">洽洽</div>
        </div>
        <div class="dis-flex6 color-fff">
          <div class="center fz64">
            <div>274W+</div>
            <div class="fz20">累计用户</div>
          </div>
          <div class="center fz64">
            <div>5W+</div>
            <div class="fz20">社区人数</div>
          </div>
          <div class="center fz64">
            <div>100W+</div>
            <div class="fz20">月均GMV</div>
          </div>
          <div class="center fz64">
            <div>3W+</div>
            <div class="fz20">月均订单</div>
          </div>
        </div>
        <div class="fz16 color-fff center time_title">数据截止2021年9月</div>
      </swiper-slide>
      <swiper-slide class="swiper_img_index">
        <div class="center band_top color-fff fz40">快消品私域会员商城首选钜汇</div>
        <div class="band_info">
          <div class="band_img_box dis-flex4"><img class="band_img" :src="require('@/assets/img/whh_logo.png')" alt=""></div>
          <div class="band_title center fz28 color-fff">娃哈哈</div>
        </div>
        <div class="dis-flex6 color-fff">
          <div class="center fz64">
            <div>800W+</div>
            <div class="fz20">累计用户</div>
          </div>
          <div class="center fz64">
            <div>2W+</div>
            <div class="fz20">社区人数</div>
          </div>
          <div class="center fz64">
            <div>110W+</div>
            <div class="fz20">月均GMV</div>
          </div>
          <div class="center fz64">
            <div>4W+</div>
            <div class="fz20">月均订单</div>
          </div>
        </div>
        <div class="fz16 color-fff center time_title">数据截止2021年9月</div>
      </swiper-slide>
      <swiper-slide class="swiper_img_index">
        <div class="center band_top color-fff fz40">快消品私域会员商城首选钜汇</div>
        <div class="band_info">
          <div class="band_img_box dis-flex4"><img class="band_img" :src="require('@/assets/img/junyao_logo.jpg')" alt=""></div>
          <div class="band_title center fz28 color-fff">均瑶味动力</div>
        </div>
        <div class="dis-flex6 color-fff">
          <div class="center fz64">
            <div>200W+</div>
            <div class="fz20">累计用户</div>
          </div>
          <div class="center fz64">
            <div>5W+</div>
            <div class="fz20">社区人数</div>
          </div>
          <div class="center fz64">
            <div>80W+</div>
            <div class="fz20">月均GMV</div>
          </div>
          <div class="center fz64">
            <div>2W+</div>
            <div class="fz20">月均订单</div>
          </div>
        </div>
        <div class="fz16 color-fff center time_title">数据截止2021年9月</div>
      </swiper-slide>
    </swiper> -->
  </div>
</template>
<script>
  // Import Swiper Vue.js components
  import { reactive } from 'vue';

  // Import Swiper styles
  import 'swiper/swiper-bundle.css'
  // import 'swiper/css';
  export default {
    setup() {
      let swiper_options= reactive({
        autoplay:{
          delay:3000,
          disableOnInteraction:false
        },
        info:[{title:'快消品私域会员商城首选钜汇',brand_name:'新希望',brand_logo:require('@/assets/img/xxw_logo.png'),user_num:'215W+',community:'27W+',month_num:'200W+',month_order:'5W+',time_end:'数据截止2021年9月'},
        {title:'快消品私域会员商城首选钜汇',brand_logo:require('@/assets/img/qiaqia_logo.png'),brand_name:'洽洽',user_num:'274W+',community:'5W+',month_num:'100W+',month_order:'3W+',time_end:'数据截止2021年9月'},
        {title:'快消品私域会员商城首选钜汇',brand_logo:require('@/assets/img/whh_logo.png'),brand_name:'娃哈哈',user_num:'800W+',community:'2W+',month_num:'110W+',month_order:'4W+',time_end:'数据截止2021年9月'},
        {title:'快消品私域会员商城首选钜汇',brand_logo:require('@/assets/img/junyao_logo.jpg'),brand_name:'均瑶味动力',user_num:'200W+',community:'5W+',month_num:'80W+',month_order:'2W+',time_end:'数据截止2021年9月'}],
        loop:true,
        speed:1000,
        slidesPerView: "auto",
        autoplayDisableOnInteraction: false,
        navigation: {
          nextElRef: ".swiper-button-next",
          prevElRef: ".swiper-button-prev",
        },
        pagination:{
          clickable:true
        }
      })
      return {
        swiper_options,
      };
    },
  };
</script>
<style lang="scss" scoped>
  .el-carousel__arrow--left,.el-carousel__arrow--right{
    font-size: 30px !important;
  }
  .content_bg{
    height: 649px;
    .content_img{
      position: absolute;
      left:50%;
      width: auto;
      max-width: 100%;
      height: auto;
      min-height: 100%;
      vertical-align: middle;
      transform: translate(-50%);
    }
    .swiper_box{
      height:100%;
      font-size: 30px;
      .el-carousel__container{
        height:649px !important;
        .el-carousel__arrow i{
          font-size: 30px;
        }
      }
      .band_img{
        width:120px;
        height:120px;
        border-radius: 50%;
      }
      .band_top{
        padding-top:60px;
      }
      .band_info{
        // padding:60px 0;
      }
      .time_title{
        padding-top:60px;
      }
    }
   
    .el-carousel__item{
      width:100%;
      height: 100%;
    }
    .swiper-button-next{
      left:20%;
    }
  }
 
  

</style>