<template>
  <div id="nav" class="pos-r" >
    <Bgimgs></Bgimgs>
    <!-- <Swipers></Swipers> -->
  </div>
  <Tableplans></Tableplans>
  <Plan></Plan>
  <Service></Service>
  <Members></Members>
  <!-- <Search></Search>
  <Footers></Footers> -->
  <!-- <Bottom></Bottom> -->
</template>
<script>
// @ is an alias to /src
import Bgimgs from '@/components/Bgimgs'
// import Swipers from '@/components/Swipers'
import Tableplans from '@/components/Tableplans'
import Plan from '@/components/Plan'
import Service from '@/components/Service'
import Members from '@/components/Member'
// import Search from '@/components/Search'
// import Footers from '@/components/Footers'
// import Bottom from '@/components/Bottom'

export default {
  name: 'Home',
  components: {
    // Swipers,
    Tableplans,
    Plan,
    Service,
    Members,
    // Search,
    // Footers,
    // Bottom,
    Bgimgs
  },
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  color: #2c3e50;
}

</style>
