import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta:{
      keepAlive:true
    }
  },
  {
    path: '/login',
    name: 'Login',
    redirect: { name: 'Band' },
    component: () => import( '../views/Login.vue'),
    meta:{
      keepAlive:false
    },
    children:[
      {
        path:'band',
        name: 'Band',
        component: () => import( '../views/Login/Band.vue'),
      },
      {
        path:'admin',
        name: 'Admin',
        component: () => import( '../views/Login/Admin.vue'),
      },
      
    ]
  },
  {
    path: '/solveWay',
    name: 'SolveWay',
    component: () => import( '../views/SolveWay.vue'),
    meta:{
      keepAlive:true
    },
  },
  {
    path: '/classicCase',
    name: 'ClassicCase',
    component: () => import( '../views/ClassicCase.vue'),
    meta:{
      keepAlive:true
    }
  },
  {
    path: '/bandGoods',
    name: 'BandGoods',
    component: () => import( '../views/BandGoods.vue'),
    meta:{
      keepAlive:true
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import( '../views/About.vue'),
    meta:{
      keepAlive:true
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
