<template>
  <div id="content" class="pos-r">
    <div class="fz40">钜汇会员商城解决方案能力矩阵</div>
    <div class="bg_biaoge dis-flex4"><img class="bg_img" src="./../../assets/img/biaoge.png" alt=""></div>
  </div>
</template>

<script>
export default {
  name:"Tableplans"
}
</script>

<style lang="scss" scoped>
#content{
  padding-top:80px;
  text-align: center;
  .bg_biaoge{
    padding:60px 0;
    .bg_img{
      width:1200px;
    }
  }
}
</style>