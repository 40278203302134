import { createStore } from 'vuex'
const changeStatus=()=>{

}
export default createStore({
  state: {
    api_token:'',
    typeData:0,
    onTopScr:false,
    onTopScrNum:0,
    changeStatus:changeStatus(),
  },
  mutations: {
    AddToken(state,payload){
      state.api_token = payload.api_token
    },
    AddType(state,payload){
      state.typeData = payload.typeData
    },
    AddTop(state,payload){
      state.onTopScr = payload.onTopScr
    },
    AddSrcNum(state,payload){
      state.onTopScrNum = payload.onTopScrNum
    },
    AddStyle(state,payload){
      state.changeStatus = payload.changeStatus
    }
  },
  actions: {

  },
  modules: {

  }
})
