<template>
  <div class="contents dis-flex4 pos-r">
    <img class="content_img" :src="urls||'https://fmcg.oss-cn-hangzhou.aliyuncs.com/pc/bg_banner_a.png'" alt="">
  </div>
</template>
<script>
export default {
  name:'Bgimgs',
  props:{
    urls:String
  },
}
</script>

<style lang="scss" scoped>
  .contents{
    width:100%;
    // height:600px;
    background-size:100% 100%;
    .content_img{
      width: auto;
      max-width: 100%;
      height: auto;
      min-height: 100%;
      vertical-align: middle;
    }
    .content_box_btn{
      width:1200px;
      height:100%;
      .box_btn{
        position: absolute;
        bottom:133px;
        left:0;
        .content_btn{
          padding:11px 30px;
          background:#fff;
          border-radius:4px ;
          margin-right:20px;
        }
        .content_btn1{
          border:2px solid #fff;
          padding:11px 30px;
          border-radius:4px ;
        }
      }
    }
  }
</style>