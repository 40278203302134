import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
// import 'lib-flexible-computer'
// import './utils/flexxible'

import $ from 'jquery'
import './assets/css/public.css'
import 'element-plus/theme-chalk/display.css'
// import './assets/css/fonts.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'

createApp(App).use(ElementPlus).use(store).use(router).mount('#app')
